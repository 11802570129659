
const getPagination = ({ totalItems = 0, pageSize = 10, currentPage = 1 }) => {
    const totalPages = Math.ceil(totalItems / pageSize);
    
}

const getParamsPagination = ({ options, search = '', params = {} }) => {
    const { page, itemsPerPage } = options || {};
    return {
        page: (page > 0 ? page - 1 : 0),
        itemsPerPage: (itemsPerPage > 0 ? itemsPerPage : 25),
        search,
        ...params
    };
}

export  { getPagination, getParamsPagination }